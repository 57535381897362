@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap");

@font-face {
  font-family: "Tempo Std";
  src: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/fonts/TempoStd-HeavyCondensed.woff2")
      format("woff2"),
    url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/fonts/TempoStd-HeavyCondensed.woff")
      format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Millik";
  src: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/fonts/MillikRegular.woff2")
      format("woff2"),
    url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/fonts/MillikRegular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/fonts/HelveticaNeueLTStd-Md.woff2")
      format("woff2"),
    url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/fonts/HelveticaNeueLTStd-Md.woff")
      format("woff");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/fonts/HelveticaNeueLTPro-Lt.woff2")
      format("woff2"),
    url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/fonts/HelveticaNeueLTPro-Lt.woff2")
      format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.material-symbols-rounded {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

h1,
h2 {
  font-family: "Helvetica", "Open Sans", sans-serif;
}

.text-sans {
  font-family: "Helvetica", "Open Sans", sans-serif;
}

.material-symbols-rounded.filled {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-8px);
  }

  60% {
    transform: translateY(-4px);
  }
}

.fadeIn,
.outAnimation {
  animation: fadeInAnimation 3s;
}

.fadeOut,
.inAnimation {
  animation: fadeInAnimation 3s;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.pin {
  transition: all 100ms linear;
}

.pin-dropped-1 {
  animation: bounce 100ms ease-in-out 100ms infinite;
}

.pin-dropped-1 {
  animation: bounce 200ms ease-in-out 200ms infinite;
}

.pin-dropped-3 {
  animation: bounce 100ms ease-in-out 300ms infinite;
}

.pin-dropped-4 {
  animation: bounce 300ms ease-in-out 400ms infinite;
}

.pin-dropped-5 {
  animation: bounce 300ms ease-in-out 500ms infinite;
}

@keyframes shine-top {
  20% {
    opacity: 0.6;
  }

  100% {
    transform: translateX(1000px);
    opacity: 0.6;
  }
}

.text-tempo {
  font-family: "Tempo Std", "Open Sans", Helvetica, sans-serif;
}

.text-millik {
  font-family: "Millik", sans-serif;
}

.text-tenorsans {
  font-family: "Tenor Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.drop-shadow-white {
  filter: drop-shadow(5px 0px 15px rgb(255 255 255 / 1));
  text-shadow: 1px 1px 20px white;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

body {
  font-family: "Helvetica", "Open Sans", Arial, Helvetica, sans-serif;
  @apply bg-dark-blue;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
  -webkit-overflow-scrolling: touch;
}

#root {
  width: 100%;
  font-family: "Helvetica", "Open Sans", Arial, Helvetica, sans-serif;
  /* overflow-x: hidden; */
}

html,
body {
  width: 100%;
  height: 100%;
}

sub,
sup {
  font-size: 62%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

.App {
  text-align: center;
  width: 100vw;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.cursor-key {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: url(https://s3.amazonaws.com/td.carnival/2024.celebration/assets/media/key_cursor.svg);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 99999;
}

#preloader {
  transition: 150ms all linear;
}

#preloader > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.isZoom #palms {
  top: 0;
}

.isZoom section {
  min-height: 350vh;
}

.isZoom #section-intro {
  min-height: 180vh;
}

.isZoom.w-zoom200 section#section-accordion {
  margin-top: 7rem;
  min-height: 200vh;
}

.isZoom.w-zoom200 #header2 h2 {
  margin-top: -12%;
}

.isZoom.w-zoom400 #header2 {
  height: 250vh;
  top: 50%;
}

.isZoom.w-zoom400 #header2 > #main-logo2 {
  margin-bottom: 20px !important;
  max-width: 100px;
}

.isZoom.w-zoom200 #section-portals {
  min-height: 170vh;
}

.isZoom.w-zoom400 #header {
  top: 0%;
  line-height: 1.2 !important;
}

.isZoom.w-zoom400.zoom-extra #header {
  line-height: 1 !important;
}

.isZoom.w-zoom400 #section-intro {
  min-height: 100vh;
}

.isZoom #featured-portal > div {
  min-height: 200vh;
}

.isZoom .bahamian-art {
  min-height: 150vh;
}

.isZoom #featured-portal {
  min-height: 200vh;
}

.isZoom #featured-portal > div > div {
  min-height: 200vh;
}

.isZoom.w-zoom200 .accordion {
  min-height: 200vh;
}

.isZoom .portals-container > div {
  margin-top: -7rem;
}

.w-m-zoom200 #section-intro {
  min-height: 150vh !important;
}

.w-m-zoom200 #scroll-indicator {
  top: 70vh;
}

.w-m-zoom200 #sweeps,
.w-m-zoom200 #sweeps > section,
.w-m-zoom200 #section-your_day {
  min-height: 100vh !important;
}

.w-m-zoom200 .ship-name {
  top: 45%;
}

.w-m-zoom200 #header {
  width: 100vw;
  font-size: 30px;
  top: 10% !important;
}

.w-m-zoom200 #main-logo {
  top: 20% !important;
}

.w-m-zoom200 #header2 {
  top: 25% !important;
}

.w-m-zoom200 footer nav ul {
  width: 90vw;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.w-m-zoom200 footer nav ul li a {
  border: none !important;
  font-size: 13px;
}

.w-m-zoom200 .ribbon {
  height: 250px;
}

.w-m-zoom200 .countdown-container > div > div {
  width: 100%;
}

.w-m-zoom200 .countdown-container > div > div > span {
  word-break: break-all;
  line-height: 1.25;
}

.w-m-zoom200 .results {
  height: 220px !important;
  width: 100% !important;
}

.w-m-zoom200 .results img {
  height: 100%;
  width: auto;
}

.w-m-zoom200 #header2 > p {
  margin: -20px -15px !important;
}

.w-m-zoom200 #header2 > #main-logo2 {
  transform: scale(0.6) !important;
}

.w-m-zoom200 #header2 > h2 {
  margin-top: -120px;
  transform: scale(0.6) !important;
}

.w-m-zoom200 #carnival-logo {
  top: 25%;
}

.w-m-zoom200 .accordion .title-tag {
  font-size: 24px !important;
}

.w-m-zoom200 #section-portals {
  min-height: 285vh !important;
  width: 100vw;
  overflow: hidden;
}

.w-m-zoom200 .vfip-tooltip,
.isZoom .vfip-tooltip {
  width: 100%;
}

.isZoom.w-zoom400 section#section-accordion {
  min-height: 100vh;
  margin-top: 13rem;
}

.isZoom.w-zoom400 #section-portals {
  min-height: 450vh;
}

/* .isZoom.w-zoom200 .portal-welcome {
  top: 41% !important;
}

.isZoom.w-zoom200 .portal-adult-friendly {
  top: 43% !important
} */

.isZoom.w-zoom200 .portal-adult-friendly:hover > .portal--title {
  left: calc(-100px) !important;
  top: -25% !important;
}

.isZoom.w-zoom200 .portal-retail:hover > .portal--title {
  left: calc(100% - 10px) !important;
  top: 70% !important;
}

.isZoom.w-zoom400 .portal-adult-friendly:hover > .portal--title {
  left: auto;
  right: 0%;
  top: -10%;
}

.isZoom.w-zoom400 .portal-private-club:hover > .portal--title {
  left: auto;
  top: -40%;
  right: 50%;
  padding-left: 15px;
}

.isZoom.w-zoom400 .portal-welcome:hover > .portal--title {
  left: auto;
  right: 0%;
  top: -20%;
  padding: 10px 15px;
}

.isZoom.w-zoom200 .portal-private-club:hover > .portal--title {
  left: auto;
  right: 0%;
  top: -90%;
  padding-left: 15px;
}

.isZoom .slider-container {
  height: 220vh;
}

.isZoom.w-zoom400 .portal-retail:hover > .portal--title {
  top: 100% !important;
}

.isZoom.w-zoom200 .portal-private-club {
  top: 40% !important;
}

.isZoom .bg-symbol {
  min-height: 170vh;
}

.isZoom .bg-lagoon {
  min-height: 200vh;
}

.isZoom.w-zoom200 #sweeps section,
.isZoom.w-zoom200 #sweeps {
  min-height: 250vh !important;
}

.isZoom.w-zoom400 #header2 {
  height: 250vh;
  top: 25%;
}

.isZoom.w-zoom400.zoom-extra #header2 {
  top: 90% !important;
  height: 60vh;
}

.isZoom.w-zoom400.zoom-extra #header2 > h2,
.isZoom.w-zoom400.zoom-extra #header2 > p {
  margin: -10px -10px;
}

.isZoom.w-zoom400.zoom-extra #header2 > small {
  margin: 1px 0;
}

/* .isZoom.w-zoom400.zoom-extra #section-intro {
  min-height: 200vh;
} */

.isZoom #section-portals {
  min-height: 400vh;
}

.isZoom .accordion {
  min-height: 350vh;
}

.isZoom #section-ships {
  min-height: 100vh;
}

.isZoom .portal-intro {
  min-height: 150vh;
}

.isZoom #carnival-ships {
  min-height: 150vh !important;
}

.isZoom.w-zoom200 .btn {
  height: auto;
}

.isZoom #header {
  top: 30%;
}

.isZoom #header2 {
  top: 20%;
}

.isZoom #main-logo {
  top: 35%;
  max-width: 206px;
}

.isZoom #carnival-ships > div:nth-child(3) {
  height: 150vh !important;
}

.isZoom .__rcs-handle-button {
  top: 40%;
}

.isZoom .embed-container {
  height: 350vh;
}

.isZoom #math-answer {
  display: block;
}

/** separate portals **/
.isZoom .portal-intro {
  min-height: 280vh !important;
}

.isZoom.w-zoom200 .portal-intro > div > video {
  max-height: 450px;
}

.isZoom .welcome-to-adult,
.isZoom .dj,
.isZoom .adult-to-welcome {
  min-height: 170vh;
}

.isZoom .flyover-module div {
  width: 100%;
}

/* .isZoom.w-zoom200 #portal-overlay {
  height: 1000px;
} */

.isZoom .unlocked-compare-slider {
  height: 150vh;
}

/* .isZoom.w-zoom200 .side-by-side {
  height: 150vh !important;
} */

#main-logo {
  @apply absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.clipped {
  -webkit-clip-path: none;
}

#palms video,
#main-video {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: 100vh;
}

@screen lg {
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
  }
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 75%;
}

#portal-headers div {
  @apply inline !important;
}

video[poster] {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

video {
  object-fit: cover;
}

.cls-1 {
  fill: none;
  fill-rule: evenodd;
  stroke-width: 0px;
}

.cls-2 {
  fill-rule: evenodd;
}

/* .star {
  fill: #ffffff
} */

#preloader-container {
  clip-path: url(#revealStar);
  -webkit-clip-path: url(#revealStar);
}

#rect {
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#header2 {
  transform: translate(-50%, -26%);
}

@screen lg {
  #header2 {
    transform: translate(-50%, -23%);
  }
}

#keyhole-container {
  position: fixed;
  width: 100%;
  height: inherit;
  will-change: transform;
  overflow: hidden;
}

#keyhole-container svg {
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  /* will-change: transform; */
}

@screen lg {
  #keyhole-container svg {
    width: 100vw;
    height: auto;
  }

  #keyhole-container svg.isChrome {
    width: 100vw;
    height: auto;
  }
}

#keyhole-video-safari {
  display: none;
}

#island {
  opacity: 0;
}

#keyhole {
  transform-origin: 50.5% 50%;
}

@supports (background: -webkit-named-image(i)) {
  #keyhole-container svg {
    height: 100vh;
    width: auto !important;
  }

  #keyhole-container svg {
    width: 100%;
    height: 100%;
  }

  #keyhole-container {
    height: inherit;
    margin-top: -25px;
    left: 50%;
    transform: translateX(-50%);
  }

  @screen lg {
    #keyhole-container svg {
      height: auto;
    }

    #headline-accordion {
      padding-top: 0px !important;
    }

    #keyhole-container {
      margin-top: 0;
    }
  }
}

#keyhole-video {
  transform: translateZ(0);
  clip-path: url(#mask);
  -webkit-clip-path: url(#mask);
  @apply fixed top-0 left-0 w-full h-full;
}

#celebrationImg {
  width: 100%;
  position: fixed;
  left: 50%;
  top: 0%;
  transform: translate(-50%, 0%);
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.bg-gradient-top::before {
  content: "";
  position: absolute;
  @apply top-0 left-0 w-full h-[45%] z-10;
  z-index: 1;
  background: rgb(5, 32, 73);
  background: linear-gradient(180deg, rgba(5, 32, 73, 0.87) 10%, rgba(0, 0, 0, 0) 100%);
}

@screen lg {
  .bg-gradient-top-dt::before {
    content: "";
    position: absolute;
    @apply top-0 left-0 w-full h-[45%] z-10;
    z-index: 1;
    background: rgb(5, 32, 73);
    background: linear-gradient(180deg, rgba(5, 32, 73, 0.87) 10%, rgba(0, 0, 0, 0) 100%);
  }
}

.bg-gradient-top-blue::before {
  content: "";
  position: absolute;
  @apply top-0 left-0 w-full h-[45%] z-10;
  z-index: 0;
  background: rgb(5, 32, 73);
  background: linear-gradient(180deg, rgba(5, 32, 73, 0.87) 10%, rgba(0, 0, 0, 0) 100%);
}

.bg-gradient-top-reverse::before {
  content: "";
  position: absolute;
  @apply top-0 left-0 w-full h-[100%] z-10;
  z-index: 0;
  background: rgb(5, 32, 73);
  background: linear-gradient(0deg, rgba(5, 32, 73, 0.97) 50%, rgba(0, 0, 0, 0) 100%);
}

.bg-gradient-bottom::after {
  content: "";
  position: absolute;
  @apply bottom-0 left-0 w-full h-[45%] z-10;
  z-index: 1;
  background: rgb(5, 32, 73);
  background: linear-gradient(0deg, rgba(5, 32, 73, 0.87) 10%, rgba(0, 0, 0, 0) 100%);
}

.bg-gradient-bottom-75::after {
  content: "";
  position: absolute;
  @apply bottom-0 left-0 w-full h-[75%] z-10;
  z-index: 1;
  background: rgb(5, 32, 73);
  background: linear-gradient(0deg, rgba(5, 32, 73, 0.87) 10%, rgba(0, 0, 0, 0) 100%);
}

#infographic .swiper-slide::after {
  display: none;
}

#inforgraphic .swiper-button-next,
#inforgraphic .swiper-button-prev {
  text-shadow: 0px 0px 10px white;
}

.swiper-slide::after {
  content: "";
  position: absolute;
  @apply -bottom-[1px] left-0 w-full h-[50%] z-10;
  z-index: 1;
  background: rgb(5, 32, 73);
  background: linear-gradient(0deg, rgb(5 32 73) 10%, rgba(0, 0, 0, 0) 100%);
}

@screen lg {
  .swiper-slide::after {
    content: "";
    position: absolute;
    @apply bottom-0 left-0 w-full h-[45%] z-10;
    z-index: 1;
    background: rgb(5, 32, 73);
    background: linear-gradient(0deg, rgba(5, 32, 73, 0.87) 10%, rgba(0, 0, 0, 0) 100%);
  }
}

.accordion {
  @apply w-full relative flex flex-col;
  will-change: transform;
}

.accordion li {
  @apply h-1/5 w-full relative;
  will-change: transform;
}

.accordion-video {
  height: 100%;
  width: 100%;
  max-width: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.accordion li.hover.active {
  width: 100% !important;
  height: 60% !important;
}

.accordion .title-tag {
  @apply uppercase text-[45px] font-black text-white drop-shadow-xl absolute left-[50%] -translate-x-1/2 bottom-[2%] opacity-60;
  transition: all 150ms ease-out;
}

.accordion {
  min-height: 100vh;
}

.accordion li.hover.inactive {
  height: 10% !important;
}

.accordion .title-tag {
  bottom: 20%;
}

.inactive .title-tag {
  top: 2%;
}

.accordion li.initial {
  width: 100%;
  height: 17% !important;
}

.accordion li.inactive {
  width: 100% !important;
  height: 7.5% !important;
}

.accordion li.active-m {
  width: 100% !important;
  height: 60% !important;
}

.box.active-m {
  font-size: 12vmax;
}

.accordion .box--play:before {
  content: "";
  position: absolute;
  @apply top-0 left-0 w-full h-[55%];
  z-index: 1;
  background: rgb(5, 32, 73);
  background: linear-gradient(180deg, rgba(5, 32, 73, 1) 8%, rgba(0, 0, 0, 0) 100%);
}

.accordion .box--play.active-m .title-tag {
  top: 50% !important;
  left: 38%;
}

.accordion .box--unwind.active .title-tag {
  top: 10% !important;
}

.accordion .box--splash.active .title-tag {
  top: 10% !important;
  left: 15%;
}

.accordion .box--savor.active .title-tag {
  top: 10% !important;
  left: 15%;
}

.accordion .box--explore.active-m .title-tag {
  left: 36%;
}

.active-m .title-tag {
  bottom: 45%;
  left: 38%;
  font-size: 8.25vmax;
  width: 100%;
}

@screen lg {
  .box:after {
    content: "";
    width: 2px;
    background: #ffffff;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  .accordion {
    @apply flex-row;
  }

  .accordion li {
    @apply w-1/5 h-full;
    transition: all 400ms linear;
  }

  .accordion li:before {
    content: "";
    position: absolute;
    @apply top-0 left-0 w-full h-[55%];
    z-index: 1;
    background: rgb(5, 32, 73);
    background: linear-gradient(180deg, rgba(5, 32, 73, 1) 8%, rgba(0, 0, 0, 0) 100%);
  }

  .accordion li.active {
    width: 80% !important;
    height: 100% !important;
  }

  .accordion .box--play.active .title-tag {
    top: 20% !important;
  }

  .accordion .box--unwind.active .title-tag {
    top: 10% !important;
  }

  .accordion .box--splash.active .title-tag {
    top: 10% !important;
    left: 18%;
  }

  .accordion .box--savor.active .title-tag {
    top: 10% !important;
    left: 15%;
  }

  .accordion li.inactive .title-tag {
    font-size: 52px;
    top: unset;
  }

  .accordion li.inactive {
    width: 5% !important;
    height: 100% !important;
  }

  .accordion li.initial {
    width: 25%;
    height: 100% !important;
  }

  #portals-header {
    box-sizing: content-box;
  }

  .accordion .title-tag {
    @apply uppercase text-[150px] font-black text-white drop-shadow-xl absolute;
  }

  .initial.active-m .title-tag {
    left: 50%;
    top: 50%;
    transform: translate(-70%, -50%);
    bottom: unset;
    text-align: center;
    font-size: 9vmax !important;
    width: 100%;
  }

  @screen lg {
    .accordion .title-tag {
      writing-mode: vertical-rl;
      @apply uppercase font-black text-white drop-shadow-xl absolute left-[19%] -translate-x-[50%] bottom-[3%];
      transform: rotate(180deg);
      font-size: 10.75vmax;
    }
  }

  @media (max-height: 720px) {
    .accordion .title-tag {
      font-size: 9vmax;
    }
  }

  .accordion-video {
    height: 100%;
    /* width: 100%; */
    max-width: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

#section-portals {
  position: relative;
}

.portals-container {
  height: calc(170px);
}

@screen lg {
  .portals-container {
    height: calc(100vh - 118px);
  }
}

/* .portals-container:before {
  content: "";
  @apply absolute top-0 left-0 w-full h-[35%];
  z-index: 1;
  background: rgb(5, 32, 73);
  background: linear-gradient(180deg, rgba(5, 32, 73, 0.77) 0%, rgba(0, 0, 0, 0) 100%);
} */

#carnival-ships::before {
  content: "";
  @apply absolute top-0 left-0 w-full h-[50%];
  z-index: 1;
  background: rgb(5, 32, 73);
  background: linear-gradient(180deg, rgba(5, 32, 73, 0.97) 0%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
}

#feature-video::before {
  content: "";
  @apply absolute top-0 left-0 w-full h-full;
  z-index: 1;
  background: rgb(5, 32, 73);
  background: linear-gradient(180deg, rgba(5, 32, 73, 0.26) 0%, rgba(5, 32, 73, 0.26) 100%);
}

/* .portals-container:after {
  content: "";
  position: absolute;
  @apply bottom-0 left-0 w-full h-[70%];
  z-index: 1;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 6%, rgba(5, 32, 73, 0.87) 68%);
} */

.bg-portals {
  max-width: 100%;
}

.map-container {
  position: relative;
}

@screen lg {
  .map-container:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0px;
    height: 150px;
    width: 100%;
    z-index: 10000;
    background: rgb(5, 32, 73);
    background: linear-gradient(0deg, rgba(5, 32, 73, 0.87) 10%, rgba(0, 0, 0, 0) 100%);
  }
}

.ribbon {
  position: relative;
  bottom: 0;
  height: 210px;
  /* height: 16%; */
  display: block;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: white;
  z-index: 10;
  width: 100%;
  overflow: hidden;
}

.ribbon > div {
  max-width: 980px;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%);
  width: 100%;
  display: block;
  position: absolute;
  align-items: center;
  margin: auto;
}

.countdown-container > div > div {
  @apply relative;
}

@screen lg {
  .ribbon {
    height: 200px;
    display: flex;
  }

  .ribbon > div {
    display: flex;
    height: 100%;
  }

  .countdown-container > div > div {
    @apply static;
  }
}

.countdown-container::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translate(-50%, -50%) rotate(90deg);
  width: 0;
  height: 0;
  border-top: solid 400px #0091d0;
  border-left: solid 60px transparent;
  border-bottom: solid 400px #0091d0;
  clear: both;
}

@screen lg {
  .ribbon {
    position: absolute;
    height: 118px;
    /* height: 16%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    color: white;
    z-index: 10;
    width: 100%;
    overflow: hidden;
  }

  #section-portals .ribbon {
    bottom: 0;
  }

  #sweeps .ribbon {
    @apply bottom-40;
  }

  .countdown-container::before {
    content: "";
    position: absolute;
    left: -60px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: solid 60px #0091d0;
    border-left: solid 60px transparent;
    border-bottom: solid 60px #0091d0;
    clear: both;
  }

  .countdown-container::after {
    content: "";
    height: inherit;
    width: 1000px;
    position: absolute;
    right: -550px;
    background-color: #0091d0;
    z-index: -1;
  }
}

.portal {
  @apply absolute cursor-pointer;
}

.portal:after {
  /**** Drop shadow ****/
  content: "";
  display: block;
  width: 20px;
  height: 3px;
  z-index: -1;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(5, 32, 73, 0.35);
  box-shadow: 0px 0px 15px 7px rgba(5, 32, 73, 0.5);
}

#featured-headline > h2 > div {
  letter-spacing: -2px;
}

@screen lg {
  #featured-headline > h2 > div {
    letter-spacing: 0px;
  }
}

.portal > .portal--title {
  @apply absolute opacity-0 bg-white/75 text-dark-blue font-black text-left pl-[35px] pr-[15px] p-2 pt-3 text-millik text-[12px] leading-[18px];
  left: 0%;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: -1;
  white-space: nowrap;
}

@screen lg {
  .portal > .portal--title {
    @apply absolute opacity-0 bg-white/75 text-dark-blue font-black text-left pl-[35px] pr-[15px] p-2 pt-3 text-millik text-[19px] leading-[18px];
    left: 0%;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: -1;
    white-space: nowrap;
  }
}

.portal > .portal--title span {
  display: block;
}

.portal-private-club .portal--title span {
  display: inline-block !important;
}

.portal-private-club .portal--title {
  white-space: normal;
  min-width: 135px;
  padding-right: 0rem;
}

.pin-unlocked .portal--title {
  opacity: 1;
  left: calc(100% - 25px);
  top: 50%;
  transform: translate(0%, -50%);
  transition: all 100ms ease-in-out;
}

.pin-unlocked.portal-welcome .portal--title {
  left: -110px;
  padding: 0.15rem 1rem 0.15rem 0.45rem;
}

.pin-unlocked.portal-adult-friendly .portal--title {
  left: calc(-113px);
  padding: 0.15rem 0.75rem 0.15rem 0.5rem;
  top: 75%;
}

.pin-unlocked.portal-retail .portal--title {
  left: calc(-95px);
  padding: 0.15rem 1rem 0.15rem 0.5rem;
  top: 70%;
}

.pin-unlocked.portal-family-friendly .portal--title {
  left: calc(-115px);
  padding: 0.15rem 1rem 0.15rem 0.5rem;
  top: 70%;
}

@screen lg {
  .portal:hover > .portal--title,
  .pin-unlocked .portal--title {
    opacity: 1;
    left: calc(100% - 25px);
    top: 50%;
    transform: translate(0%, -50%);
    transition: all 100ms ease-in-out;
  }

  .pin-unlocked.portal-welcome .portal--title {
    left: calc(100% - 25px);
    transform: translate(0%, -50%);
    top: 75%;
    @apply pl-[35px] pr-[15px] p-2 pt-3;
  }

  .pin-unlocked.portal-retail .portal--title {
    @apply pl-[30px] pr-[15px] p-2 pt-3;
    left: calc(100% - 30px);
    top: 73%;
  }

  .pin-unlocked.portal-adult-friendly .portal--title {
    left: calc(100% - 25px);
    transform: translate(0%, -50%);
    @apply pl-[35px] pr-[15px] p-2 pt-3;
    top: 75%;
  }

  .pin-unlocked.portal-family-friendly .portal--title {
    left: calc(100% - 25px);
    transform: translate(0%, -50%);
    @apply pl-[20px] pr-[15px] p-2 pt-3;
    top: 75%;
  }
}

.countdown-container {
  @apply relative;
}

#section-your_day {
  background-image: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/media/bg-coastline-m.jpg");

  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

@screen lg {
  #section-your_day {
    background-image: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/media/bg-coastline.jpg");
    background-size: cover;
    background-position: center;
  }
}

:focus {
  outline: 3px solid #009eb3 !important;
  outline-offset: 1px !important;
}

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
}

.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}

.toggle-switch-inner:before {
  content: "On";
  text-transform: uppercase;
  padding-left: 10px;
  background-color: #10559a;
  color: #fff;
}

.toggle-switch-disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.toggle-switch-disabled:before {
  background-color: #ddd;
  cursor: not-allowed;
}

.toggle-switch-inner:after {
  content: "Off";
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}

.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}

.slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  margin: auto;
  display: block;
}

.slideshow-wrapper .unlocked-swiper {
  height: 50vh;
}

@screen lg {
  .slideshow-wrapper .unlocked-swiper {
    height: 100%;
  }
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  border-radius: 10px;

  background: #0091d0;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0091d0, #e3f2fa, #0091d0);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0091d0, #e3f2fa, #0091d0);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 5px;
}

input[type="range"]:focus {
  outline: none;
}

section:focus {
  outline: none !important;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0px solid;
  border-radius: 50%;
  height: 46px;
  width: 46px;
  max-width: 80px;
  position: relative;
  bottom: 23px;
  background-color: #052049 !important;
  cursor: -webkit-grab;
  background-image: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/media/slider-arrows.svg");
  background-size: cover;
  -webkit-transition: border 1000ms ease;
  transition: border 1000ms ease;
  border-color: rgb(255, 255, 255);
}

.slider:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 1px 0px 10px 0 rgba(0, 0, 0, 0.4);
}

/* Track: Mozilla Firefox */
.slider::-moz-range-track {
  height: 15px;
  background: rgb(0, 145, 208);
  background: linear-gradient(
    94deg,
    rgba(0, 145, 208, 1) 0%,
    rgba(227, 242, 250, 1) 51%,
    rgba(20, 154, 212, 1) 100%
  );
  border-radius: 16px;
  padding-top: 5px;
}

.slider::-moz-range-thumb {
  cursor: -webkit-grab;
  background-image: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/media/slider-arrows.svg");
  background-size: cover;
  border: 0px solid;
  border-radius: 50%;
  height: 45px;
  width: 45px;
}

@media (max-height: 720px) {
  .portals-container > div {
    margin-top: -4rem;
  }
}

#header2 > #main-logo2,
#header2 > h2,
#header2 > p {
  opacity: 0;
  visibility: hidden;
}

.results--copy {
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  padding: 0.5rem;
  cursor: pointer;
  z-index: 2;
}

@screen lg {
  .results--copy {
    left: unset;
    transform: translate(0%, -50%);
    z-index: 10;
  }
}

.results--copy > div > p:nth-child(2) {
  opacity: 1;
  height: auto;
}

.results .results--overlay {
  @apply opacity-100 h-full w-full bg-dark-blue/30 absolute top-0 left-0;
  transition: all 150ms ease-in;
  opacity: 1;
  z-index: 1;
}

@screen lg {
  .results--copy > div > p:nth-child(2) {
    opacity: 0;
    height: 0;
    transition: all 300ms ease-in;
  }

  .results .results--overlay {
    @apply opacity-0 h-full w-full bg-dark-blue/30 absolute top-0 left-0;
    transition: all 150ms ease-in;
  }

  .results:hover .results--overlay {
    opacity: 1 !important;
    z-index: 1;
  }
}

.results:hover > div > div > p:nth-child(2) {
  opacity: 1;
  height: auto;
}

.results:hover > div > div > p:nth-child(2) {
  opacity: 1;
  height: auto;
}

.results img {
  @apply absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: none;
  z-index: 0;
}

@screen lg {
  .results img {
    @apply absolute h-full;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: none;
    z-index: 0;
  }
}

.bg-sunset {
  background-image: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/media/bg-thanks.jpg");
  background-size: cover;
  min-height: 600px;
  background-position: center center;
}

.bg-sunset::before {
  content: "";
  position: absolute;
  @apply top-0 left-0 w-full h-[75%] z-10;
  z-index: 1;
  background: rgb(5, 32, 73);
  background: linear-gradient(180deg, rgba(5, 32, 73, 0.87) 40%, rgba(0, 0, 0, 0) 100%);
}

.gradient-topper::before {
  content: "";
  position: absolute;
  @apply -top-[40%] left-0 w-full h-[40%] z-50;
  background: rgb(5, 32, 73);
  background: linear-gradient(180deg, rgba(5, 32, 73, 0) 0%, rgba(5, 32, 73, 1) 70%);
}

@screen lg {
  .gradient-topper::before {
    @apply hidden;
  }
}

.__rcs-handle-button {
  background-image: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/media/slider-arrows.svg");
  background-size: cover;
  top: 60%;
  position: absolute;
  z-index: 9999999;
  pointer-events: auto !important;
}

@screen lg {
  .__rcs-handle-button {
    background-image: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/media/slider-arrows.svg");
    background-size: cover;
    top: 48%;
    position: absolute;
    z-index: 9999999;
    pointer-events: auto !important;
  }

  .adult-friendly .__rcs-handle-button {
    background-image: url("https://s3.amazonaws.com/td.carnival/2024.celebration/assets/media/portals/adult-friendly/unlocked/slider-arrows.svg");
    background-size: cover;
    top: 50%;
    position: absolute;
    z-index: 9999999;
    pointer-events: auto !important;
  }
}

.__rcs-handle-arrow {
  display: none;
}

.ship-name svg {
  pointer-events: none;
}

.__rcs-handle-root {
  pointer-events: auto !important;
  z-index: 9999;
  display: block;
}

@media (max-width: 720px) {
  .__rcs-handle-root {
    min-height: 100vh;
  }

  .ship-name svg {
    height: 100%;
  }
}

@screen md {
  .ship-name svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.text-shadow {
  text-shadow: 0px 1px 10px #000000a1;
}

.text-shadow-2 {
  text-shadow: 4px 4px 4px #0000003b;
}

.custom-pagination {
  @apply flex items-center justify-center;
}

.center-pagination {
  left: 50% !important;
  transform: translateX(-50%);
  margin-bottom: 10px;
}

.swiper-pagination {
  position: absolute;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

@screen lg {
  #slideoverlay ~ .custom-pagination {
    left: 66% !important;
  }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: auto !important;
}

.swiper-pagination-bullet,
.swiper-custom-bullet {
  width: 16px !important;
  height: 16px !important;
  margin: 0px 10px !important;
  border: 2px solid #ffffff !important;
  background-color: transparent !important;
  opacity: 1 !important;
  display: block;
  border-radius: 100%;
  cursor: pointer;
}

.swiper-pagination-bullet-active,
.swiper-custom-bullet-active {
  background-color: #ffffff !important;
}

@screen lg {
  .portal--copy {
    top: unset;
    bottom: 19%;
  }
}

#scroll-indicator,
#main-logo {
  /* transform: translateZ(0); */
  bottom: 0;
  height: 100vh;
  will-change: transform;
}

#scroll-indicator {
  top: 79%;
}

@media screen and (min-width: 992px) and (max-height: 699px) {
  #scroll-indicator {
    top: 65%;
  }
}

@media screen and (min-width: 992px) and (min-height: 700px) {
  #scroll-indicator {
    top: 83%;
  }
}

#main-logo {
  max-width: 536px;
  height: auto;
}

#mapMobile .swiper {
  width: 100%;
  height: 100%;
}

#mapMobile .swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.max-w-auto {
  max-width: auto !important;
}

@screen lg {
  #imageMap {
    top: 0;
    min-height: 600px;
  }

  #section-portals {
    min-height: 640px;
  }
}

#pin-featured > div {
  opacity: 1;
  left: 60%;
  top: 65%;
  transform: translateX(0%) translateY(-50%);
  width: 285px;
  height: 80px;
  transition: width 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: -1;
  padding-left: 45px;
}

#pin-featured > div.foodbeverage-pin:before {
  content: "New!";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #dc1125;
  @apply text-center text-white px-6;
}

#pin-featured > div.family-friendly-pin {
  opacity: 0;
  left: 50%;
  top: 55%;
  transform: translateX(0%) translateY(-50%);
  width: 0%;
  height: 74px;
  transition: width 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: -1;
  padding-left: 45px;
}

#pin-featured > img {
  margin-bottom: -25%;
}

#pin-featured > div > span {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  margin-top: 20px;
}

.isActive {
  width: 82.5% !important;
}

.accordion li.notActive {
  width: 8.75% !important;
}

.accordion li.isInitial.notActive:first-child {
  width: 66% !important;
}

.accordion li.isInitial.notActive {
  width: 33% !important;
}

@screen xl {
  .unlocked-compare-slider > div > div {
    width: 100%;
  }
}

.flyover-image {
  background-size: 2200px auto;
}

@keyframes fade-in {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-out {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* .text-animated-1 {
  animation: fade-in 1s infinite 14s;
  animation-fill-mode: forwards;
  animation-direction: alternate-reverse;

}

.text-animated-2 {
  opacity: 0;
  animation: fade-out 1s infinite 6s;
  animation-fill-mode: forwards;
  animation-direction: alternate-reverse;
} */

.mobile-dining {
  height: 100% !important;
}

.mobile-dining .flyover-image {
  background-size: 800px auto;
}

@screen lg {
  .mobile-dining {
    height: 100% !important;
  }

  .private-dining .flyover-image {
    background-size: 1900px auto;
  }
}
